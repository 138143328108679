import { useAtom, useAtomValue } from "jotai";
import type { RefObject } from "react";

import {
  productGroupsPageState,
  productGroupsState,
} from "../../jotai/products";
import { Typography } from "../../library";
import PaginatedListButtons from "../../library/PaginatedListButtons";
import ProductGroup from "./ProductGroup";

const GROUPS_PER_PAGE = 3;

interface ProductGroupsProps {
  productsRef?: RefObject<HTMLDivElement>;
}

export default function ProductGroups({ productsRef }: ProductGroupsProps) {
  const productGroups = useAtomValue(productGroupsState);
  const [page, setPage] = useAtom(productGroupsPageState);
  const numPages = Math.ceil(productGroups.length / GROUPS_PER_PAGE);
  const pageStart = page * GROUPS_PER_PAGE;
  const pageEnd = pageStart + GROUPS_PER_PAGE;
  const showTitle = !!productsRef;

  if (!productGroups.length) {
    return null;
  }

  return (
    <div className="self-stretch inline-flex flex-col justify-start items-start gap-4">
      {showTitle && (
        <Typography className="font-semibold" color="brand.boldest.enabled">
          Featured product groups
        </Typography>
      )}
      <div className="self-stretch inline-flex justify-start items-start gap-4">
        {productGroups.slice(pageStart, pageEnd).map((productGroup, index) => (
          <ProductGroup
            productGroup={productGroup}
            key={`${index}-${productGroup.name}`}
            productsRef={productsRef}
          />
        ))}
      </div>
      <div className="self-stretch inline-flex justify-between items-center">
        <PaginatedListButtons index={page} count={numPages} onClick={setPage} />
      </div>
    </div>
  );
}
